import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { loadAvailableGroomers } from '@/actions/groomers';
import Button from '@/atoms/button';
import useSignupSteps from '@/hooks/use-signup-steps';
import ListLoader from '@/organisms/loaders/list-loader';
import ProgressBar from '@/organisms/progress-bar';
import { editForm } from '@/store/reducers/request-appointment-form';
import { getRandomGroomer, isEmpty } from '@/util';
import AllGroomers from './all-groomers';
import GroomersPerAvailability from './groomers-per-availability';
import {
  filterGroomersByAnimalType,
  filterGroomersWithoutAvailability,
} from './util';

function GroomerInfo() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { location, dateStart, locationUser, service } = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const { type, uuid: activePetUuid } = useAppSelector(
    (state) => state.petAppointments.activePet,
  );
  const { locationUser: lastBookedGroomer } = useAppSelector(
    (state) => state.lastUserAppointment,
  );
  const { type: petBeingCreatedType } = useAppSelector(
    (state) => state.createPetForm.data,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [allGroomers, setAllGroomers] = useState([]);
  const [currentAvailableGroomers, setCurrentDateGroomers] = useState([]);
  const [futureAvailableGroomers, setFutureDateGroomers] = useState([]);
  const userPetType = type || petBeingCreatedType;
  const { numberOfSteps } = useSignupSteps();
  const [hasPreSelectedDate, setHasPreSelectedDate] = useState(false);

  useEffect(() => {
    setHasPreSelectedDate(!!dateStart);
    const [date, time] = dateStart
      ? dateStart.split('T')
      : [dayjs().format('YYYY-MM-DD'), dayjs().format('HH:mm:ss')];

    setIsLoading(true);
    dispatch(
      loadAvailableGroomers({
        luid: location,
        date,
        time,
        suid: service?.uuid,
        animal: activePetUuid,
      }),
    )?.then(({ success, data }) => {
      setIsLoading(false);
      if (success) {
        const groomersFilteredByAvailability =
          filterGroomersWithoutAvailability(data.data);

        const groomersFilteredByAnimalType = filterGroomersByAnimalType(
          groomersFilteredByAvailability,
          userPetType,
        );

        const groomers = groomersFilteredByAnimalType;

        const currentGroomers = [] as any[];
        const futureGroomers = [] as any[];
        groomers.forEach((groomer: any) => {
          if (groomer.availableOnSelectedDate) {
            currentGroomers.push(groomer);
          } else futureGroomers.push(groomer);
        });

        if (
          (dateStart &&
            futureGroomers.length === 0 &&
            currentGroomers.length === 1) ||
          (!dateStart && groomers.length === 1)
        ) {
          const availableGroomer = groomers[0];
          dispatch(
            editForm({
              key: 'locationUser',
              value: availableGroomer,
            }),
          );
          const { nextAvailableDate } = availableGroomer || {};
          navigate(`/booking-select-slot?next-date=${nextAvailableDate}`);
          return;
        }

        setAllGroomers(groomers);
        setFutureDateGroomers(futureGroomers);
        setCurrentDateGroomers(currentGroomers);

        // If lastBookedGroomer is in this provider, select it
        if (lastBookedGroomer?.uuid) {
          const groomer = groomers.filter(
            (groom: any) => groom.user.uuid === lastBookedGroomer.uuid,
          )[0];
          if (groomer) selectGroomer(groomer);
        }
      }
    });
  }, []);

  const selectGroomer = (groomer: any) => {
    dispatch(editForm({ key: 'locationUser', value: groomer || 'any' }));
  };

  const onNext = () => {
    if (locationUser !== 'any' && !locationUser.canHandleRequestedSlot) {
      const { nextAvailableDate } = locationUser;
      return navigate(`/booking-select-slot?next-date=${nextAvailableDate}`);
    }

    if (hasPreSelectedDate) {
      if (locationUser === 'any') {
        const randomGroomer = getRandomGroomer(allGroomers);
        selectGroomer(randomGroomer);
      }
    }

    return navigate('/booking-select-slot');
  };

  return (
    <div className="booking-pet-container flex-column align-start justify-center">
      <div className="mobile-page-container w-100">
        <h1 className="mb-12">Groomer details</h1>
        <ProgressBar
          maxSteps={numberOfSteps}
          currentStep={4}
          className="mb-32 width-100"
        />
        {isLoading ? (
          <ListLoader />
        ) : (
          <div className="pb-100-mobile">
            {dateStart ? (
              <GroomersPerAvailability
                currentAvailableGroomers={currentAvailableGroomers}
                futureAvailableGroomers={futureAvailableGroomers}
                selectGroomer={selectGroomer}
                userPetType={userPetType}
              />
            ) : (
              <AllGroomers
                groomers={allGroomers}
                selectGroomer={selectGroomer}
                userPetType={userPetType}
              />
            )}
          </div>
        )}
      </div>
      {allGroomers.length > 0 && (
        <div className="mobile-main-action-btn w-100 mw-420">
          <Button
            text="Next"
            onClick={onNext}
            variant={isEmpty(locationUser) ? 'disabled' : 'default'}
            size="medium"
          />
        </div>
      )}
    </div>
  );
}

export default GroomerInfo;
