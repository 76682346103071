import { Route, Routes } from 'react-router-dom';

import AboutUs from '@/pages/about-us';
import AddAddress from '@/pages/add-address';
import AdditionalPetDetails from '@/pages/additional-pet-details';
import AdminSearch from '@/pages/admin-search';
import AdminSignup from '@/pages/admin-signup';
import Blog from '@/pages/blog';
import BookingConfirmation from '@/pages/booking-confirmation';
import BookingSignup from '@/pages/booking-signup';
import ConsumerLandingDeepLink from '@/pages/consumer-landing-deep-link';
import CreatePet from '@/pages/create-pet';
import FinishSignup from '@/pages/finish-signup';
import FinishSignupMFA from '@/pages/finish-signup-mfa';
import GroomerSelect from '@/pages/groomer-select';
import Home from '@/pages/home';
import LinkMobileRedirect from '@/pages/link-mobile-redirect';
import { LocationService } from '@/pages/location-service';
import Login from '@/pages/login';
import MFA from '@/pages/mfa';
import Payment from '@/pages/payment';
import Search from '@/pages/search';
import SettingsPage from '@/pages/settings';
import Signup from '@/pages/signup';
import NewPassword from '@/pages/new-password';
import NotFound from '@/pages/not-found';
import PetProfile from '@/pages/pet-profile';
import PetSelect from '@/pages/pet-select';
import PhoneVerification from '@/pages/phone-verification';
import ProviderProfile from '@/pages/provider-profile';
import { ProviderSlots } from '@/pages/provider-slots';
import ResetPassword from '@/pages/reset-password';
import TermsOfService from '@/pages/terms-of-service';
import UploadPetAvatar from '@/pages/upload-pet-avatar';
import SlotSelect from '@/pages/slot-select';

import UnsubscribePage from '@/pages/unsubscribe';
import AddOnsSelect from '@/pages/add-ons-select';
import PrivateRoute from './private-route';

const getRoutes = () => (
  <Routes>
    {/* General. */}
    <Route path="*" element={<NotFound />} />
    <Route path="link" element={<LinkMobileRedirect />} />
    <Route path="terms" element={<TermsOfService />} />
    <Route path="openapp" element={<ConsumerLandingDeepLink />} />
    <Route path="about" element={<AboutUs />} />
    <Route path="blog" element={<Blog />} />

    {/* Admin */}
    <Route
      path="search-consumer"
      element={
        <PrivateRoute adminCheck>
          <AdminSearch />
        </PrivateRoute>
      }
    />
    <Route path="admin/_signup" element={<AdminSignup />} />

    {/* Home */}
    <Route path="/" element={<Home />} />
    <Route path="/advanced-search" element={<Home />} />

    {/* Regular signup */}
    <Route path="login" element={<Login />} />
    <Route path="signup" element={<Signup />} />
    <Route path="signup-mfa" element={<MFA />} />

    {/* Booking signup */}
    <Route path="signup/address" element={<AddAddress />} />
    <Route path="signup/finish-signup" element={<FinishSignup />}>
      <Route path=":code" element={<FinishSignup />} />
    </Route>
    <Route path="signup/phone-verification" element={<PhoneVerification />} />
    <Route path="signup/mfa" element={<FinishSignupMFA />} />
    <Route
      path="signup/additional-pet-details"
      element={<AdditionalPetDetails />}
    />

    {/* Password reset */}
    <Route path="trigger-password-reset" element={<ResetPassword />} />
    <Route path="password-reset" element={<NewPassword />}>
      <Route path=":code" element={<NewPassword />} />
    </Route>

    {/* Providers */}
    <Route path=":city/:type/:slug" element={<ProviderProfile />} />
    <Route path=":city/:type/:slug/:serviceId" element={<LocationService />} />
    <Route path="provider/:luid/slots" element={<ProviderSlots />} />
    <Route path="pet/groomers/chicago" element={<Search />}>
      <Route path=":neighborhood" element={<Search />} />
      <Route path="type/:providerType" element={<Search />} />
    </Route>
    <Route path=":city/:type/:slug/add-ons" element={<AddOnsSelect />} />

    {/* Create pet */}
    <Route path="create-pet" element={<CreatePet />} />
    <Route path="upload-avatar" element={<UploadPetAvatar />} />
    <Route path="user/:userId/pet" element={<PetProfile />}>
      <Route path=":petId" element={<PetProfile />} />
    </Route>

    {/* Logged in user checkout */}
    <Route path="booking-select-pet" element={<PetSelect />} />
    <Route path="booking-select-groomer" element={<GroomerSelect />} />
    <Route path="booking-select-slot" element={<SlotSelect />} />
    <Route path="booking-payment" element={<Payment />} />
    <Route path="booking-confirmation" element={<BookingConfirmation />} />

    {/* Signup checkout */}
    <Route path="signup/pet-details" element={<BookingSignup />} />
    <Route path="signup/user-details" element={<BookingSignup />} />
    <Route path="signup/booking-payment" element={<Payment />} />
    <Route
      path="signup/booking-confirmation"
      element={<BookingConfirmation />}
    />

    {/* Settings */}
    <Route path="settings" element={<SettingsPage />} />
    <Route path="unsubscribe" element={<UnsubscribePage />} />
  </Routes>
);

export default getRoutes;
