import { createSlice } from '@reduxjs/toolkit';

export const REQUEST_APPOINTMENT_FORM_STORAGE_KEY =
  'state:request-appointment-form';

const updateLocalStorage = (state: any) => {
  localStorage.setItem(
    REQUEST_APPOINTMENT_FORM_STORAGE_KEY,
    JSON.stringify(state),
  );
};

interface RequestAppointmentFormState {
  animal: any;
  location: any;
  locationUser: any;
  service: any;
  addOns: string[];
  dateStart: any;
  provider: any;
  providerPath: any;
}

const initialState: RequestAppointmentFormState = {
  animal: null,
  location: null,
  locationUser: {},
  service: null,
  addOns: [],
  dateStart: null,
  provider: null,
  providerPath: null,
};
const requestAppointmentForm = createSlice({
  name: 'requestAppointmentForm',
  initialState,
  reducers: {
    editForm(state: any, { payload }) {
      const { key, value } = payload;
      (state as any)[key] = value;
      updateLocalStorage(state);
    },
    initializeForm(_, { payload }) {
      return payload;
    },
    clearForm() {
      updateLocalStorage(initialState);
      sessionStorage.removeItem('requestAppointmentForm');
      return initialState;
    },
  },
});

const { actions, reducer } = requestAppointmentForm;

export const { editForm, initializeForm, clearForm } = actions;

export default reducer;
