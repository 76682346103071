import React from 'react';
import { useLocalStorage, useLocation } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { isNotEmptyArray } from '@/util';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { useAppDispatch, useAppSelector } from '.';

export const EXTRA_SIGNUP_STEP_STORAGE_KEY = 'extra-signup-step';

type BOOKING_STEP = 'provider-profile' | 'add-ons' | 'pet-select';

export default function useSignupSteps(): {
  numberOfSteps: number;
  setExtraSignupStep: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  deleteExtraSignupStep: () => void;
  goToNextStep: ({ currentStep }: { currentStep: BOOKING_STEP }) => void;
} {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [extraSignupStep, setExtraSignupStep, deleteExtraSignupStep] =
    useLocalStorage<boolean | undefined>(EXTRA_SIGNUP_STEP_STORAGE_KEY, false);
  const { token, data: userData } = useAppSelector((state) => state.user);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const { addOns } = useAppSelector((state) => state.location);

  const providerProfileNext = () => {
    if (isNotEmptyArray(addOns)) return goToAddOns();
    if (!token) return navigate('/signup/pet-details');
    return goToPetSelect();
  };

  const goToAddOns = () => navigate(`${location.pathname}/add-ons`);

  const addOnsNext = () => {
    if (!token) return navigate('/signup/pet-details');
    return goToPetSelect();
  };

  const goToPetSelect = () => {
    const isPetDisabled = (pet: any) =>
      activePet.type?.value !== pet.type?.value ||
      activePet.size?.value !== pet.size?.value;

    if (
      userData &&
      isNotEmptyArray(userData?.animals) &&
      userData.animals.length === 1 &&
      !isPetDisabled(userData.animals[0])
    ) {
      dispatch(setActivePet(userData.animals[0]));
      return goToGroomerSelect();
    }

    return navigate('/booking-select-pet');
  };

  const goToGroomerSelect = () => navigate('/booking-select-groomer');

  const goToNextStep = ({ currentStep }: { currentStep: BOOKING_STEP }) => {
    if (currentStep === 'provider-profile') return providerProfileNext();
    if (currentStep === 'add-ons') return addOnsNext();
    if (currentStep === 'pet-select') return goToGroomerSelect();
  };

  const numberOfSteps = extraSignupStep ? 7 : 6;

  return {
    numberOfSteps,
    setExtraSignupStep,
    deleteExtraSignupStep,
    goToNextStep,
  };
}
