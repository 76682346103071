import { Helmet } from 'react-helmet-async';

import Checkout from '@/organisms/checkout';

import AddOnsInfo from './add-ons-info';

export default function AddOnsSelect() {
  return (
    <>
      <Helmet title="Select add-ons for booking | PawCare" />
      <Checkout>
        <AddOnsInfo />
      </Checkout>
    </>
  );
}
