import { Link, useLocation } from 'react-router-dom';
import { checkoutFlowURLs } from '@/util/constants';

function LoggedOut() {
  const location = useLocation();
  const displaySignupPaths = ['/', '/about', '/blog'];
  const displaySignup = displaySignupPaths.includes(location.pathname);
  const isProvidersProfile = location.pathname.includes('/p/');
  const isCheckoutPage =
    checkoutFlowURLs.includes(location.pathname) || isProvidersProfile;
  const loginLink =
    location.pathname === '/signup/user-details'
      ? '/login?appointment-signup=true'
      : `/login${location.search}`;

  return (
    <div className="flex-row">
      <Link
        className="login-button"
        to={loginLink}
        state={{ from: { pathname: location.pathname } }}
      >
        Log in
      </Link>
      {displaySignup && (
        <Link className="login-button ml-50" to={`/signup${location.search}`}>
          Sign up
        </Link>
      )}
      {!isCheckoutPage && (
        <a className="button ml-50" href="https://pawcare.pet/">
          For groomers
        </a>
      )}
    </div>
  );
}

export default LoggedOut;
