import './payment.scss';
import { Helmet } from 'react-helmet-async';
import Checkout from '@/organisms/checkout';
import useSignupSteps from '@/hooks/use-signup-steps';
import PaymentInfo from './payment-info';

export default function Payment() {
  const { numberOfSteps } = useSignupSteps();

  return (
    <>
      <Helmet title="Complete booking | PawCare" />
      <Checkout
        showProgressBar
        progressBarData={{
          currentStep: numberOfSteps,
          maxSteps: numberOfSteps,
        }}
        hideInvoice={false}
        mobileTitle="Complete booking"
        mobileSubtitle="Your card will not be charged for the service until after your
          appointment."
      >
        <PaymentInfo />
      </Checkout>
    </>
  );
}
