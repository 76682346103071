import { useAppDispatch, useAppSelector } from '@/hooks';

import Button from '@/atoms/button';
import useSignupSteps from '@/hooks/use-signup-steps';
import ProgressBar from '@/organisms/progress-bar';
import { editForm } from '@/store/reducers/request-appointment-form';
import { Service } from '@/store/reducers/location';
import classNames from 'classnames';
import { isNotEmptyArray } from '@/util';

function AddOnsInfo() {
  const dispatch = useAppDispatch();
  const { addOns: availableAddOns } = useAppSelector((state) => state.location);
  const { addOns } = useAppSelector((state) => state.requestAppointmentForm);
  const { numberOfSteps, goToNextStep } = useSignupSteps();

  const onSelectAddOn = (addOn: any) => {
    const updatedAddOns = addOns.includes(addOn.uuid)
      ? addOns.filter((uuid: string) => uuid !== addOn.uuid)
      : [...addOns, addOn.uuid];
    dispatch(editForm({ key: 'addOns', value: updatedAddOns }));
  };

  const onNext = () => {
    goToNextStep({ currentStep: 'add-ons' });
  };

  return (
    <div className="add-ons-select-container flex-column align-start justify-center">
      <div className="mobile-page-container w-100">
        <h1 className="mb-12">Choose add-ons</h1>
        <ProgressBar
          maxSteps={numberOfSteps}
          currentStep={2}
          className="mb-32 width-100"
        />
        {isNotEmptyArray(availableAddOns) ? (
          <ul className="flex-column no-style-ul gap-12 pb-100-mobile">
            {availableAddOns.map((addOn: Service) => (
              <li key={addOn.uuid}>
                <button
                  onClick={() => onSelectAddOn(addOn)}
                  className={classNames(
                    'flex-column w-100 p-16 radius-12 pointer gap-8',
                    addOns.includes(addOn.uuid)
                      ? 'border-primary-orange b-light-orange'
                      : 'border-light-purple b-white',
                  )}
                >
                  <div className="flex-row w-100 justify-between">
                    <h4 className="f18 bold">{addOn.name}</h4>
                    <p className="f18 bold">{addOn.price.localized}</p>
                  </div>
                  {addOn.description && (
                    <p className="f16 text-primary-purple text-left">
                      {addOn.description}
                    </p>
                  )}
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="mb-12">No add-ons available</p>
        )}
      </div>
      <div className="mobile-main-action-btn w-100">
        <Button text="Next" onClick={onNext} size="medium" />
      </div>
    </div>
  );
}

export default AddOnsInfo;
