import './list-item.scss';

import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import classNames from 'classnames';
import dayjs from 'dayjs';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import {
  cancelAppointment,
  patchAppointment,
  patchGeneralRequest,
} from '@/actions/appointments';
import { loadPetAppointments } from '@/actions/pet';
import Button from '@/atoms/button';
import Dropdown from '@/atoms/dropdown';
import toast from '@/atoms/toast';
import { isAppointmentCompleted } from '@/organisms/appointments/util';
import CancelAppointmentModal from '@/organisms/cancel-appointment-modal';
import CancelAppointmentSuccess from '@/organisms/cancel-appointment-success';
import RescheduleAppointmentSuccess from '@/organisms/reschedule-appointment-success';
import RescheduleConfirmationModal from '@/organisms/reschedule-confirmation-modal';
import RescheduleReviewModal from '@/organisms/reschedule-preview-modal';
import ProviderSearch from '@/organisms/user-search/provider-search';
import {
  openDrawer,
  requestFulfilled,
  requestOngoing,
} from '@/store/reducers/ui';
import { getRequestStatusLabel, isPastDateTime, prettifyDate } from '@/util';
import ViewMoreModal from '@/pages/search/view-more-modal';

import { ConfirmationProvider } from '../confirmation-provider';

interface ListItemProps {
  item: any;
  canCancel?: boolean;
  isPastRequest?: boolean;
  isNew?: boolean;
  isGeneral?: boolean;
  selectedSuggestedTime?: any;
}
export function ListItem({
  item,
  canCancel,
  isPastRequest,
  isNew,
  isGeneral,
  selectedSuggestedTime,
}: ListItemProps) {
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const { data } = useAppSelector((state) => state.user);
  const [rescheduledDateTime, setRescheduledDateTime] = useState('');
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
  const [isRescheduleReviewModalOpen, setIsRescheduleReviewModalOpen] =
    useState(false);
  const [
    isRescheduleConfirmationModalOpen,
    setIsRescheduleConfirmationModalOpen,
  ] = useState(false);
  const [isRescheduleSuccessModalOpen, setIsRescheduleSuccessModalOpen] =
    useState(false);
  const [isCancelModalOpen, openCancelModal] = useState(false);
  const [isCancelSuccessModalOpen, setIsCancelSuccessModalOpen] =
    useState(false);
  const [selectedProvider, selectProvider] = useState();
  const [isProviderSearchOpen, openProviderSearch] = useState(false);
  const [isConfirmationModalOpen, openConfirmationModal] = useState(false);
  const [displayActions, setDisplayActions] = useState(!!isMobile);
  const { uuid, animal, dateStart, location, locationUser, service, note } =
    item;
  const lastAction = Array.isArray(item.actions)
    ? item.actions[item.actions.length - 1]?.action
    : undefined;
  const isCompleted = lastAction?.value === 'completed';
  const canReviewSuggestedTimes =
    item.suggested &&
    !isPastDateTime(dateStart) &&
    !isAppointmentCompleted(item);

  const onClickButton = (action: any) => {
    if (action === 'Cancel') {
      return openCancelModal(true);
    }
    if (action === 'Reschedule') {
      return setIsViewMoreModalOpen(true);
    }
  };

  useEffect(() => {
    if (selectedSuggestedTime) setIsRescheduleReviewModalOpen(true);
  }, []);

  const onConfirmAppointment = (data: any, appointmentId: any) => {
    dispatch(patchGeneralRequest(data, appointmentId))?.then(({ success }) => {
      if (success) {
        dispatch(loadPetAppointments(animal));
        openConfirmationModal(false);
        openProviderSearch(false);
        toast({ text: 'The appointment has been assigned to a provider' });
      }
    });
  };

  const onSelectRescheduleSlot = (value: any) => {
    const { date, time } = value.slot;
    setRescheduledDateTime(`${date}T${time}`);
    setIsViewMoreModalOpen(false);
    setIsRescheduleConfirmationModalOpen(true);
  };

  const onCancelAppointment = async () => {
    dispatch(requestOngoing());
    const apptCancelled = await dispatch(cancelAppointment(uuid));
    dispatch(requestFulfilled());
    if (apptCancelled?.success) {
      openCancelModal(false);
      setIsCancelSuccessModalOpen(true);
    }
  };

  const onChangeAppointmentTime = async (newTime: any) => {
    if (!newTime) return setRescheduledDateTime('');

    setRescheduledDateTime(newTime);
    dispatch(requestOngoing());

    const apptChanged = await dispatch(
      patchAppointment(uuid, {
        uuid: {
          animal: animal?.uuid,
          location: location?.uuid,
          location_user: locationUser?.uuid,
          service: service?.uuid,
        },
        note,
        date_start: newTime,
      }),
    );
    dispatch(requestFulfilled());
    setIsRescheduleReviewModalOpen(false);
    setIsRescheduleConfirmationModalOpen(false);

    if (apptChanged?.success) {
      await dispatch(loadPetAppointments(animal.uuid));
      setIsRescheduleSuccessModalOpen(true);
    } else {
      setRescheduledDateTime('');
    }
  };

  const onReviewSuggestedTimes = () => {
    setIsRescheduleReviewModalOpen(true);
  };

  const onSubmitReviewedTime = (value: any) => {
    setIsRescheduleReviewModalOpen(false);

    if (value === 'cancel') return onCancelAppointment();
    return onChangeAppointmentTime(value);
  };

  if (!item.location) return null;

  return (
    <div
      className="pet-profile-list-item flex-row align-start"
      onMouseEnter={
        !isPastRequest || !isMobile ? () => setDisplayActions(true) : undefined
      }
      onMouseLeave={
        !isPastRequest || !isMobile ? () => setDisplayActions(false) : undefined
      }
    >
      <button
        onClick={() =>
          dispatch(
            openDrawer({ type: 'appointment', data: { appointment: item } }),
          )
        }
        className={classNames(
          'pet-profile-list-item-container flex-row',
          (isNew || isGeneral) && 'pet-profile-list-item-container--new',
        )}
      >
        <div className="pet-profile-list-item-container-header">
          <div className="pet-profile-list-item-container-info f13">
            {item.service && (
              <p className="bold text-primary-purple">
                {prettifyDate(dateStart)}
              </p>
            )}

            <h4 className="f15">{item.location.name}</h4>

            <p className="pet-profile-list-item-detail text-primary-purple">
              <b>Address:</b> {item.location.address1} {item.location.locality},{' '}
              {item.location.region} {item.location.postalCode}
            </p>
            {item.service && item.summary && (
              <p className="pet-profile-list-item-detail text-primary-purple">
                <b>Service:</b>{' '}
                {`${item.service?.name}${
                  item.addOns?.length
                    ? `, ${item.addOns.map((addOn: any) => addOn.name).join(', ')}`
                    : ''
                }${
                  item.summary.total?.localized
                    ? ` • ${item.summary.total.localized}`
                    : ''
                }`}
              </p>
            )}
            {item.locationUser && item.locationUser.firstName && (
              <p className="pet-profile-list-item-detail text-primary-purple">
                <b>Groomer:</b> {item.locationUser.firstName}
              </p>
            )}
            {item.note && (
              <p className="pet-profile-list-item-detail text-primary-purple">
                <b>Note:</b> {item.note}
              </p>
            )}
            {canReviewSuggestedTimes && (
              <Button
                className="mt-10"
                size="small"
                onClick={onReviewSuggestedTimes}
                text="Review suggested times"
              />
            )}
          </div>
        </div>

        {/* --- ACTIONS --- */}

        {displayActions && isGeneral && data.isAdmin && (
          <div className="pet-profile-list-item-actions-admin">
            <Button
              size="small"
              onClick={() => openProviderSearch(true)}
              text="Find provider"
            />
          </div>
        )}

        <div className="pet-profile-list-item-actions">
          {canCancel && !isCompleted && (
            <Dropdown
              id="actions"
              size="small"
              icon={MoreVertIcon}
              hideSelection
              // items={['Reschedule', 'Cancel']} // TODO: Hiding Reschedule while it is reworked on the BE
              items={['Cancel']}
              onClick={(value) => onClickButton(value)}
            />
          )}
        </div>

        {/* --- LABELS --- */}
        {(isPastRequest || isCompleted) && (
          <span
            className={classNames(
              `status-label status-label--${lastAction?.id} align-self-start mt-8`,
            )}
          >
            {getRequestStatusLabel(lastAction)}
          </span>
        )}

        {/* --- MODALS --- */}

        {/* Admin: Provider search */}
        {isProviderSearchOpen && (
          <ReactModal
            ariaHideApp={false}
            isOpen={isProviderSearchOpen}
            onRequestClose={() => openProviderSearch(false)}
          >
            <ProviderSearch
              forceFirstSerch
              searchParams={{ lat: item.latitude, long: item.longitude }}
              onSelect={(provider) => {
                if (
                  !provider.locations.length ||
                  !provider.locations[0].services.length
                ) {
                  toast({
                    text: "The selected provider doesn't have services yet",
                  });
                  return null;
                } else {
                  openConfirmationModal(true);
                  selectProvider(provider);
                }
              }}
            />
          </ReactModal>
        )}
        {/* Admin: Link appointment to provider */}
        {isConfirmationModalOpen && (
          <ReactModal
            ariaHideApp={false}
            isOpen={isConfirmationModalOpen}
            onRequestClose={() => {
              openConfirmationModal(false);
            }}
          >
            <ConfirmationProvider
              provider={selectedProvider}
              appointment={item}
              onConfirm={onConfirmAppointment}
              openConfirmationModal={openConfirmationModal}
            />
          </ReactModal>
        )}
        {/* Cancel appointment confirmation check */}
        {isCancelModalOpen && (
          <CancelAppointmentModal
            isOpen={isCancelModalOpen}
            openModal={openCancelModal}
            dateStart={dateStart}
            onCancelAppointment={onCancelAppointment}
          />
        )}
        {isCancelSuccessModalOpen && (
          <CancelAppointmentSuccess
            isOpen={isCancelSuccessModalOpen}
            setIsOpen={setIsCancelSuccessModalOpen}
            onClose={() => dispatch(loadPetAppointments(animal))}
          />
        )}
        {/* Select slot for rescheduling */}
        {isViewMoreModalOpen && (
          <ViewMoreModal
            isOpen={isViewMoreModalOpen}
            setIsOpen={setIsViewMoreModalOpen}
            locationUuid={item.location.uuid}
            dateStart={dayjs(item.dateStart).format('YYYY-MM-DD')}
            onSelectSlot={onSelectRescheduleSlot}
          />
        )}
        {isRescheduleReviewModalOpen && (
          <RescheduleReviewModal
            isOpen={isRescheduleReviewModalOpen}
            onClose={() => setIsRescheduleReviewModalOpen(false)}
            appointment={item}
            selectedSuggestedTime={selectedSuggestedTime}
            onSubmitReviewedTime={onSubmitReviewedTime}
          />
        )}
        {/* Reschedule confirmation check */}
        {isRescheduleConfirmationModalOpen && (
          <RescheduleConfirmationModal
            isOpen={isRescheduleConfirmationModalOpen}
            setIsOpen={setIsRescheduleConfirmationModalOpen}
            dateStart={rescheduledDateTime}
            onConfirm={onChangeAppointmentTime}
            onCancel={() => setRescheduledDateTime('')}
          />
        )}
        {/* Reschedule success */}
        {isRescheduleSuccessModalOpen && (
          <RescheduleAppointmentSuccess
            isOpen={isRescheduleSuccessModalOpen}
            setIsOpen={setIsRescheduleSuccessModalOpen}
            onClose={() => {
              setRescheduledDateTime('');
              dispatch(loadPetAppointments(animal));
            }}
            dateStart={rescheduledDateTime}
          />
        )}
      </button>
    </div>
  );
}
