import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import { loadProvider } from '@/actions/providers';
import DefaultAvatar from '@/atoms/default-avatar';
import { closeDrawer } from '@/store/reducers/ui';
import { isEmpty } from '@/util';
import { clearForm } from '@/store/reducers/request-appointment-form';

interface ProviderInfoProps {
  appointment: any;
}

export default function ProviderInfo({ appointment }: ProviderInfoProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { location, locationUser, provider } = appointment;
  const { uuid: providerUuid } = provider;
  const { data: providerData, isLoading: isLoadingProvider } = useAppSelector(
    (state) => state.provider,
  );
  const providerAvatar = isLoadingProvider
    ? null
    : providerData?.links?.image?.desktop?.small;

  useEffect(() => {
    dispatch(loadProvider(providerUuid));
  }, [providerUuid]);

  if (!location || isEmpty(location)) return null;

  return (
    <div className="flex-row mb-12 p-24 border-light-purple radius-24">
      <button
        onClick={(e) => {
          e.stopPropagation();
          dispatch(clearForm());
          navigate(`/${location.locality?.toLowerCase()}/p/${location.slug}`);
          dispatch(closeDrawer());
        }}
        className="no-style-btn pointer"
      >
        <DefaultAvatar avatar={providerAvatar} size="small" />
      </button>
      <div className="flex-column ml-8 gap-4">
        <p className="f13 bold">{location.name}</p>
        {locationUser && locationUser.firstName && (
          <p className="f11 c-primary-purple">
            <b>Groomer:</b> {locationUser.firstName}
          </p>
        )}
        <p className="f11 c-primary-purple">
          <b>Address:</b> {location.address1} {location.locality},{' '}
          {location.region} {location.postalCode}
        </p>
      </div>
    </div>
  );
}
