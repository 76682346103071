import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Link, useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';
import { appointmentFinder } from '@/actions/appointments';
import ProviderCard from '@/atoms/provider-card';
import HomeLoader from '@/organisms/loaders/home-loader';
import HomeLoaderMobile from '@/organisms/loaders/home-loader-mobile';
import RequestAppointmentModal from '@/pages/search/request-appointment-modal';
import ViewMoreModal from '@/pages/search/view-more-modal';
import { clearForm, editForm } from '@/store/reducers/request-appointment-form';
import { chicagoLatLng } from '@/util/constants';

export function ProvidersList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { providers, calendar, isLoading } = useAppSelector(
    (state) => state.hardInventory,
  );
  const { isRequestModalOpen } = useAppSelector((state) => state.ui);
  const [selectedSlot, selectSlot] = useState();
  const [isViewMoreModalOpen, setIsViewMoreModalOpen] = useState(false);
  const [viewMoreLocation, setViewMoreLocation] = useState<null | {
    uuid: string;
  }>(null);

  useEffect(() => {
    dispatch(
      appointmentFinder({
        lat: chicagoLatLng.lat,
        lng: chicagoLatLng.lng,
        displayAllTime: true,
        radius: 50,
      }),
    );
  }, []);

  const onRequestAppointment = (data: any) => {
    if (!selectedSlot && !data) return;
    dispatch(clearForm());
    const { provider, slot } = data || selectedSlot;
    const { date, time } = slot;
    dispatch(editForm({ key: 'provider', value: provider }));
    dispatch(editForm({ key: 'dateStart', value: `${date}T${time}` }));
    dispatch(editForm({ key: 'location', value: provider.uuid }));
    navigate(`/${provider.locality?.toLowerCase()}/p/${provider.slug}`);
  };

  const onSelectSlot = (data: any) => {
    selectSlot(data);
    onRequestAppointment(data);
  };

  const onViewMore = (location: any) => {
    dispatch(clearForm());
    if (isMobile) {
      navigate(`/provider/${location.uuid}/slots`);
    } else {
      setViewMoreLocation(location);
      setIsViewMoreModalOpen(true);
    }
  };

  const onClickCard = (cardLink: any) => {
    dispatch(clearForm());
    dispatch(editForm({ key: 'dateStart', value: null }));
    navigate(cardLink);
  };

  const providersList = providers.map((item: any) => (
    <ProviderCard
      key={item.uuid}
      item={item}
      selectSlot={onSelectSlot}
      onViewMore={onViewMore}
      calendar={calendar}
      maxSlots="3"
      stopPropagation
      cardLink={`/${item.locality.replace(/ /g, '-').toLowerCase()}/p/${
        item.slug
      }`}
      onClickCard={onClickCard}
    />
  ));

  const clonedProvidersList = providersList.map((el) => el).slice(0, 8);
  const Loader = isMobile ? HomeLoaderMobile : HomeLoader;

  return (
    <div className="home-providers-list">
      <div className="flex-row justify-between">
        <h2 className="mb-25 mt-25">Recommended</h2>
        <Link to="/pet/groomers/chicago">View all</Link>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="home-providers-list-container">
          {clonedProvidersList}
        </div>
      )}
      <ViewMoreModal
        isOpen={isViewMoreModalOpen}
        setIsOpen={setIsViewMoreModalOpen}
        locationUuid={viewMoreLocation?.uuid || ''}
        onSelectSlot={onSelectSlot}
      />
      {isRequestModalOpen && (
        <RequestAppointmentModal
          isOpen={isRequestModalOpen}
          location={viewMoreLocation}
        />
      )}
    </div>
  );
}
